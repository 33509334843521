import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.png";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={classes.info}>
          <div className={classes.contacts}>
            <p>
              {t('footer_contacts')}
            </p>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
            <a href="tel:441241340123">
              +441241340123
            </a>
          </div>
          <div className={classes.docs}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t('footer_docs_terms')}
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t('footer_docs_privacy')}
            </a>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t('footer_docs_aml')}
            </a>
          </div>
        </div>
        <div className={classes.disclaimer}>
          <p className={classes.disclaimerTitle}>
            {t('footer_disclaimaer_title')}
          </p>
          <p>
            <Trans>
              {t('footer_disclaimaer_text')}
            </Trans>
          </p>
          <p className={classes.rights}>
            {t('footer_rights')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
